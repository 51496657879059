import React from "react"

const Offer2 = ({ data }) => {
  return (
    <div className="grid-wrap mb-4 w-full grid grid-cols-2 md:grid-cols-4 grid-rows-2 md:grid-rows-1 gap-4 h-96">
      {data.nodes.length === 0
        ? Array.apply(null, Array(2)).map((item, i) => (
            <div className={`grid-img-wrap  ${i == 1 && "col-span-3"}`}>
              <img
                className="w-full h-full object-cover"
                src={
                  i == 1
                    ? "https://media.kubric.io/api/assetlib/85994c0a-15e9-4adc-aac3-944d930450d5.png"
                    : "https://media.kubric.io/api/assetlib/f4755b49-47c7-4317-bd7c-b4921e14b846.png"
                }
                alt="product image"
              />
            </div>
          ))
        : data.distinct.map(position => {
            let card = data.nodes.filter(
              elem => elem.meta.position == position
            )[0]
            return (
              <div
                className={`grid-img-wrap  ${position == "2" && "col-span-3"}`}
              >
                <a href={card.meta.url ? card.meta.url : "/"}>
                  <img
                    className="w-full h-full object-cover"
                    src={card.url}
                    alt="product image"
                  />
                </a>
              </div>
            )
          })}
    </div>
  )
}

export default Offer2
