import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const HeroBanner = ({ data }) => {
  return (
    <div className="hero-banner-wrap my-4">
      {data.length === 0 ? (
        <StaticImage
          className="w-full"
          src="https://media.kubric.io/api/assetlib/d99a0b52-81b4-4fc4-8bdc-2952ee8bbaf3.png"
          alt="placeholder"
        />
      ) : (
        data.map(banner => (
          <div className="banner-img-wrap w-full">
            <a
              target="_blank"
              href={
                banner.meta.url !== null
                  ? banner.meta.url
                  : "/"
              }
            >
              <img
                className="w-full h-full object-cover"
                src={banner.url}
                alt="grid image"
              />
            </a>
          </div>
        ))
      )}
    </div>
  )
}

export default HeroBanner
