import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroBanner from "../components/heroBanner"
import Grid4x1 from "../components/grid4x1"
import Offer1 from "../components/offer1"
import Offer2 from "../components/offer2"
import FeaturedProducts from "../components/featuredProducts"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" />
    <HeroBanner data={data.heroBanner.nodes} />
    <Grid4x1 data={data.grid4x1} />
    <Offer1 data={data.offer1} />
    <Offer2 data={data.offer2} />
    <FeaturedProducts data={data.featured_product}/>
  </Layout>
)

export const query = graphql`
  {
    heroBanner: allMasonContentCreative(
      filter: {meta: {contentType: {eq: "leaderboard_banner"}}}
      sort: {fields: updated_time, order: ASC}
      limit: 1
    ) {
      nodes {
        meta {
          url
        }
        url
      }
    }
    grid4x1: allMasonContentCreative(
      filter: {meta: {contentType: {eq: "featured_categories"}}}
      sort: {fields: updated_time, order: ASC}
    ) {
      nodes {
        meta {
          url
          position
        }
        url
      }
      distinct(field: meta___position)
    }
    offer1: allMasonContentCreative(
      filter: {meta: {contentType: {eq: "offer_1"}}}
      sort: {fields: updated_time, order: ASC}
    ) {
      nodes {
        meta {
          url
          position
        }
        url
      }
      distinct(field: meta___position)
    }
    offer2: allMasonContentCreative(
      filter: {meta: {contentType: {eq: "offer_2"}}}
      sort: {fields: updated_time, order: ASC}
    ) {
      nodes {
        meta {
          url
          position
        }
        url
      }
      distinct(field: meta___position)
    }
    featured_product: allMasonContentCreative(
      filter: {meta: {contentType: {eq: "featured_product"}}}
      sort: {fields: updated_time, order: ASC}
    ) {
      nodes {
        meta {
          url
          position
        }
        url
      }
      distinct(field: meta___position)
    }
  }
`

export default IndexPage
