import React from "react"

const Offer1 = ({ data }) => {
  return (
    <div className="grid-wrap mb-4 w-full grid grid-cols-2 md:grid-cols-4 grid-rows-2 md:grid-rows-1 gap-4 h-96">
      {data.nodes.length === 0
        ? Array.apply(null, Array(2)).map((item, i) => (
            <div className={`grid-img-wrap ${i == 0 && "col-span-3"}`}>
              <img
                className="w-full h-full object-cover"
                src={
                  i === 0
                    ? "https://media.kubric.io/api/assetlib/edb3440e-f118-4682-9c9e-1d4c78a6f435.png"
                    : "https://media.kubric.io/api/assetlib/3d75365d-92c5-4102-8fcb-afcaafd4ceb3.png"
                }
                alt="product image"
              />
            </div>
          ))
        : data.distinct.map(position => {
            let card = data.nodes.filter(
              elem => elem.meta.position == position
            )[0]
            return (
              <div
                className={`grid-img-wrap ${position == "1" && "col-span-3"}`}
              >
                <a href={card.meta.url ? card.meta.url : "/"}>
                  <img
                    className="w-full h-full object-cover"
                    src={card.url}
                    alt="product image"
                  />
                </a>
              </div>
            )
          })}
    </div>
  )
}

export default Offer1
